import ReactDOM from "react-dom"

import ReportsVideo from "./videos/reports.mp4"
import FeedbackVideo from "./videos/feedback.mp4"
import MMForGoogleVideo from "./videos/google-microsoft.mp4"
import AchievementsVideo from "./videos/achievements.mp4"
import HQVideo from "./videos/create.mp4"
import MobileVideo from "./videos/mobile.mp4"
import Community from "./videos/community.mp4"
import GuidedVideo from "./videos/guided.mp4"
import ExternalVideo from "./videos/external-pd.mp4"
import SyncVideo from "./videos/sync.mp4"
import DashboardVideo from "./videos/dashboard.mp4"

import App from "./App"

export const slideData = {
  reporting: {
    title: "Reporting & Tracking of All PD for Leaders",
    description:
      "Leaders can track and report on badges earned, last access of learners, micro-courses and learning paths completed, learners’ progress on their PD goals, and even continuing education hours and compliance reporting.",
    video: ReportsVideo,
  },
  community: {
    title: "MobileMind Community",
    description:
      "MobileMind Community is the first competency-based, crowd-sourced PD platform. Create and share quality-assured micro-courses across all topics, methodologies, and technology with other MobileMind districts.",
    video: Community,
  },
  dashboard: {
    title: "Learner Dashboard",
    description:
      "Each learner has a personalized dashboard that includes quick links, support features, and engagement widgets that keep them on the path to completing their PD goals.",
    video: DashboardVideo,
  },
  mobile: {
    title: "Mobile App",
    description:
      "The professional learning hub, already available anytime, anywhere is even more accessible through the MobileMind app. Through learning streaks and rewards, the app keeps learners engaged and focused on continuous learning.",
    video: MobileVideo,
  },
  feedback: {
    title: "Human & Automated Feedback",
    description:
      "Feedback on competency-based challenge submissions is provided through automation and via real human feedback from MobileMind's certified educators, making feedback just as personalized as micro-courses.",
    video: FeedbackVideo,
  },
  google: {
    title: "Ready-Made Marketplace",
    description:
      "MobileMind provides anytime, anywhere access to gamified, personalized micro-courses for all educational apps.",
    video: MMForGoogleVideo,
  },
  guided: {
    title: "Guided, In-App, Activity Based Learning",
    description:
      "Patent-pending technology provides guided in-app learning right within the Google Workspace for Education environment. Following learners from page to page, a challenge box appears and accompanies them as they access their Google apps, guiding them through their activity-based challenge submissions.",
    video: GuidedVideo,
  },
  badges: {
    title: "Badges & Achievements",
    description:
      "Celebrating progress along the way, learners earn digital badges and micro-credentialing to demonstrate their competency in each skill set they master.",
    video: AchievementsVideo,
  },
  hq: {
    title: "HQ: Creating Learning Opportunities",
    description:
      "Create, manage, facilitate, track, and report on all professional development from compliance to curriculum. As requirements and needs change, HQ allows districts to quickly respond to teachers evolving PD needs with new learning opportunities without negatively impacting student achievement.",
    video: HQVideo,
  },
  external: {
    title: "External PD",
    description:
      "External PD allows teachers to request credit for trainings or conferences attended outside of the district. These credits are consolidated into the teachers backpack with all other MobileMind activities.",
    video: ExternalVideo,
  },
  sync: {
    title: "MobileMind Events",
    description:
      "Seamlessly manage all asynchronous and synchronous PD efforts in the same learning hub. MobileMind Events offers a full-service synchronous PD scheduler for single and group PD events like workshops and conferences.",
    video: SyncVideo,
  },
}

ReactDOM.render(<App />, document.getElementById("root"))
