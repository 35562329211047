import { Component } from "react"
import classNames from "classnames"
import IdleTimer from "react-idle-timer"

class HubContainer extends Component {
  constructor(props) {
    super(props)
    this.idleTimer = null
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)
    this.state = {
      hoverItem: null,
      hoverIndex: -1,
      cycledItems: [],
    }
  }

  handleOnActive(event) {
    clearInterval(this.interval)
    this.setState({ isIdle: false })
    this.setState({ hoverItem: null, hoverIndex: 0, cycledItems: [] })
  }

  handleOnIdle(event) {
    this.setState({ isIdle: true })
    this.idleCycle()
  }

  idleCycle() {
    this.interval = setInterval(() => {
      const steps = [
        "google",
        "dashboard",
        "guided",
        "feedback",
        "badges",
        "hq",
        "community",
        "sync",
        "external",
        "mobile",
        "reporting",
      ]

      let hoverIndex =
        this.state.hoverIndex === 10 ? 0 : this.state.hoverIndex + 1
      let index = this.state.hoverIndex === -1 ? 0 : this.state.hoverIndex

      if (this.state.hoverIndex < 11 && this.state.hoverIndex !== -1) {
        if (this.state.cycledItems.length < 10) {
          this.setState({
            cycledItems: this.state.cycledItems.concat([steps[index]]),
          })
        } else {
          this.setState({ cycledItems: [] })
        }
      }

      this.setState({ hoverItem: steps[hoverIndex], hoverIndex })
    }, 3000)
  }

  render() {
    const { hoverItem, cycledItems } = this.state
    const { activeItem } = this.props

    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref
          }}
          timeout={15000}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />

        {!activeItem && (
          <div className="featuresWrapper">
            <div className="column">
              {cycledItems.map((feature, index) => {
                if (index % 2 === 0) {
                  return (
                    <h2 key={index}>
                      {feature === "google" && <>Ready-Made Marketplace</>}
                      {feature === "guided" && (
                        <>
                          Guided, In-App, <br /> Activity Based Learning
                        </>
                      )}
                      {feature === "badges" && <>Badges & Achievements</>}
                      {feature === "community" && <>MobileMind Community</>}
                      {feature === "external" && <>External PD</>}
                    </h2>
                  )
                }
                return null
              })}
            </div>
            <div className="column">
              {cycledItems.map((feature, index) => {
                if (index % 2 !== 0) {
                  return (
                    <h2 key={index}>
                      {feature === "dashboard" && <>Learner Dashboard</>}
                      {feature === "reporting" && (
                        <>
                          Reporting & Tracking for
                          <br /> All PD Leaders
                        </>
                      )}
                      {feature === "feedback" && (
                        <>Human & Automated Feedback</>
                      )}
                      {feature === "hq" && (
                        <>HQ: Creating Learning Opportunities</>
                      )}
                      {feature === "sync" && <>MobileMind Events</>}
                      {feature === "mobile" && <>Mobile App</>}
                    </h2>
                  )
                }
                return null
              })}
            </div>
          </div>
        )}

        <div className="labelWrapper">
          {hoverItem === "external" && (
            <span className="hoverLabel">
              <span className="green">External PD</span>
            </span>
          )}
          {hoverItem === "community" && (
            <span className="hoverLabel">
              <span className="green">
                MobileMind <br />
                Community
              </span>
            </span>
          )}
          {hoverItem === "feedback" && (
            <span className="hoverLabel">
              <span className="green">
                Human & <br />
                Automated Feedback
              </span>
            </span>
          )}
          {hoverItem === "google" && (
            <span className="hoverLabel">
              <span className="green">Ready-Made Marketplace</span>
            </span>
          )}
          {hoverItem === "guided" && (
            <span className="hoverLabel">
              <span className="green">
                Guided, In-App, Activity Based Learning
              </span>
            </span>
          )}
          {hoverItem === "dashboard" && (
            <span className="hoverLabel">
              <span className="green">Learner Dashboard</span>
            </span>
          )}
          {hoverItem === "badges" && (
            <span className="hoverLabel">
              <span className="green">
                Badges & <br />
                Achievements
              </span>
            </span>
          )}
          {hoverItem === "hq" && (
            <span className="hoverLabel">
              <span className="green">HQ: Creating Learning Opportunities</span>
            </span>
          )}
          {hoverItem === "sync" && (
            <span className="hoverLabel">
              <span className="green">MobileMind Events</span>
            </span>
          )}
          {hoverItem === "mobile" && (
            <span className="hoverLabel">
              <span className="green">Mobile App</span>
            </span>
          )}
          {hoverItem === "reporting" && (
            <span className="hoverLabel">
              <span className="green">
                Reporting & Tracking of All PD for Leaders
              </span>
            </span>
          )}
        </div>

        <div className="hubWrapper bg" />
        <div className="hubWrapper bg two" />
        <div className={"hubResponsiveWrapper"}>
          <div className={classNames("hubContainer", activeItem && "selected")}>
            <div className={classNames("hubWrapper")}>
              <div
                className={classNames(
                  "hub-icons",
                  hoverItem && "hovering",
                  hoverItem
                )}
              >
                <div className="iconWrapper community">
                  <div
                    className={classNames(
                      "icon community",
                      hoverItem === "community" && "active"
                    )}
                  />
                </div>

                <div className="iconWrapper external">
                  <div
                    className={classNames(
                      "icon external",
                      hoverItem === "external" && "active"
                    )}
                  />
                </div>

                <div className="iconWrapper feedback">
                  <div
                    className={classNames(
                      "icon feedback",
                      hoverItem === "feedback" && "active"
                    )}
                  />
                </div>

                <div className="iconWrapper google">
                  <div
                    className={classNames(
                      "icon google",
                      hoverItem === "google" && "active"
                    )}
                  />
                </div>

                <div className="iconWrapper guided">
                  <div
                    className={classNames(
                      "icon guided",
                      hoverItem === "guided" && "active"
                    )}
                  />
                </div>

                <div className="iconWrapper dashboard">
                  <div
                    className={classNames(
                      "icon dashboard",
                      hoverItem === "dashboard" && "active"
                    )}
                  />
                </div>

                <div className="iconWrapper badges">
                  <div
                    className={classNames(
                      "icon badges",
                      hoverItem === "badges" && "active"
                    )}
                  />
                </div>

                <div className="iconWrapper hq">
                  <div
                    className={classNames(
                      "icon hq",
                      hoverItem === "hq" && "active"
                    )}
                  />
                </div>

                <div className="iconWrapper sync">
                  <div
                    className={classNames(
                      "icon sync",
                      hoverItem === "sync" && "active"
                    )}
                  />
                </div>

                <div className="iconWrapper mobile">
                  <div
                    className={classNames(
                      "icon mobile",
                      hoverItem === "mobile" && "active"
                    )}
                  />
                </div>

                <div className="iconWrapper reporting">
                  <div
                    className={classNames(
                      "icon reporting",
                      hoverItem === "reporting" && "active"
                    )}
                  />
                </div>
              </div>

              <svg
                className={classNames(
                  this.state.selectedItem,
                  hoverItem && "hovering",
                  hoverItem
                )}
                id="hubImage"
                width="700px"
                height="700px"
                viewBox="0 0 219 219"
              >
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g transform="translate(-126.000000, -120.000000)">
                    <g transform="translate(126.171000, 120.971000)">
                      <path
                        d="M62.957,28.977 C56.044,33.061 49.71,38.019 44.107,43.702 L56.707,56.302 C61.244,51.748 66.369,47.781 71.956,44.52 L62.957,28.977"
                        id="Fill-18"
                        fill="#7F7E7C"
                      ></path>
                      <path
                        d="M81.428,3.562 C71.929,6.056 62.938,9.802 54.643,14.615 L62.957,28.977 C69.965,24.838 77.567,21.601 85.604,19.421 L81.428,3.562"
                        id="Fill-19"
                        fill="#B2D490"
                      ></path>
                      <path
                        d="M85.604,19.421 L85.604,19.421 C77.567,21.601 69.965,24.838 62.957,28.977 L71.956,44.52 C77.608,41.219 83.737,38.645 90.213,36.922 L85.604,19.421"
                        id="Fill-20"
                        fill="#C3E3E8"
                      ></path>
                      <path
                        d="M94.538,53.346 L94.538,53.346 C89.544,54.675 84.818,56.661 80.458,59.208 L89.2,74.308 C92.214,72.497 95.487,71.07 98.95,70.098 L94.538,53.346"
                        id="Fill-22"
                        fill="#B0D38D"
                      ></path>
                      <path
                        d="M108.962,-5.86197757e-14 C99.448,0.013 90.221,1.252 81.428,3.562 L85.604,19.421 C93.065,17.399 100.901,16.288 108.986,16.219 L108.962,-5.86197757e-14"
                        id="Fill-23"
                        fill="#9ECB79"
                      ></path>
                      <path
                        d="M32.245,31.84 C25.58,38.504 19.781,46.032 15.02,54.238 L29.673,62.72 C33.654,55.761 38.517,49.372 44.107,43.702 L32.245,31.84"
                        id="Fill-25"
                        fill="#CCE8EC"
                      ></path>
                      <path
                        d="M44.107,43.702 L44.107,43.702 C38.517,49.372 33.654,55.761 29.673,62.72 L44.988,71.586 C48.227,65.989 52.174,60.854 56.707,56.302 L44.107,43.702"
                        id="Fill-26"
                        fill="#7D7C7B"
                      ></path>
                      <path
                        d="M56.707,56.302 L56.707,56.302 C52.174,60.854 48.227,65.989 44.988,71.586 L59.672,80.088 C62.166,75.77 65.209,71.809 68.703,68.298 L56.707,56.302"
                        id="Fill-27"
                        fill="#9ED4DF"
                      ></path>
                      <path
                        d="M68.703,68.298 L68.703,68.298 C65.209,71.809 62.166,75.77 59.672,80.088 L74.944,88.929 C76.647,85.927 78.726,83.167 81.118,80.713 L68.703,68.298"
                        id="Fill-28"
                        fill="#A2CD7E"
                      ></path>
                      <path
                        d="M29.845,154.486 L14.969,163.1 C19.73,171.324 25.536,178.869 32.209,185.548 L44.328,173.429 C38.726,167.784 33.847,161.421 29.845,154.486"
                        id="Fill-30"
                        fill="#797977"
                      ></path>
                      <path
                        d="M59.619,137.25 L44.884,145.781 C48.12,151.407 52.072,156.568 56.613,161.144 L68.666,149.091 C65.164,145.565 62.116,141.587 59.619,137.25"
                        id="Fill-31"
                        fill="#A8D084"
                      ></path>
                      <path
                        d="M74.946,128.378 L59.619,137.25 C62.116,141.587 65.164,145.565 68.666,149.091 L81.143,136.615 C78.741,134.155 76.654,131.389 74.946,128.378"
                        id="Fill-32"
                        fill="#B1D48E"
                      ></path>
                      <path
                        d="M53.935,123.495 L37.337,127.865 C39.018,134.364 41.617,140.1 44.884,145.781 L59.619,137.25 C57.099,132.871 55.232,128.504 53.935,123.495"
                        id="Fill-33"
                        fill="#DDEBC9"
                      ></path>
                      <path
                        d="M44.328,173.429 L44.328,173.429 L32.209,185.548 C38.883,192.229 46.424,198.041 54.645,202.812 L63.184,188.062 C56.273,184.007 49.937,179.08 44.328,173.429"
                        id="Fill-34"
                        fill="#AEABA8"
                      ></path>
                      <path
                        d="M68.666,149.091 L68.666,149.091 L56.613,161.144 C61.16,165.725 66.3,169.715 71.907,172.994 L80.46,158.22 C76.136,155.694 72.173,152.62 68.666,149.091"
                        id="Fill-36"
                        fill="#A7A4A2"
                      ></path>
                      <path
                        d="M80.46,158.22 L80.46,158.22 L71.907,172.994 C77.502,176.266 83.562,178.826 89.965,180.554 L94.382,164.039 C89.446,162.71 84.773,160.739 80.46,158.22"
                        id="Fill-37"
                        fill="#ebebeb"
                      ></path>
                      <path
                        d="M89.255,143.027 L80.46,158.22 C84.773,160.739 89.446,162.71 94.382,164.039 L98.888,147.189 C95.469,146.223 92.236,144.813 89.255,143.027"
                        id="Fill-38"
                        fill="#8D8C8A"
                      ></path>
                      <path
                        d="M85.45,197.442 L81.083,213.77 C89.964,216.134 99.29,217.406 108.913,217.424 L108.96,200.686 C100.829,200.613 92.949,199.489 85.45,197.442"
                        id="Fill-39"
                        fill="#81C9D8"
                      ></path>
                      <path
                        d="M89.965,180.554 L89.965,180.554 L85.45,197.442 C92.949,199.489 100.829,200.613 108.96,200.686 L109.011,183.115 C102.424,183.084 96.041,182.193 89.965,180.554"
                        id="Fill-40"
                        fill="#A5D7E1"
                      ></path>
                      <path
                        d="M98.888,147.189 L98.888,147.189 L94.382,164.039 C99.064,165.299 103.984,165.983 109.06,166.005 L109.109,148.685 C105.571,148.625 102.145,148.109 98.888,147.189"
                        id="Fill-42"
                        fill="#777777"
                      ></path>
                      <path
                        d="M174.803,173.895 C169.091,179.568 162.644,184.5 155.613,188.531 L163.806,202.684 C172.031,197.886 179.57,192.042 186.239,185.33 L174.803,173.895"
                        id="Fill-43"
                        fill="#767674"
                      ></path>
                      <path
                        d="M162.102,161.193 C157.519,165.803 152.335,169.813 146.679,173.099 L155.613,188.531 C162.644,184.5 169.091,179.568 174.803,173.895 L162.102,161.193"
                        id="Fill-44"
                        fill="#6E6F6E"
                      ></path>
                      <path
                        d="M149.978,149.069 C146.448,152.628 142.452,155.725 138.09,158.264 L146.679,173.099 C152.335,169.813 157.519,165.803 162.102,161.193 L149.978,149.069"
                        id="Fill-45"
                        fill="#807F7E"
                      ></path>
                      <path
                        d="M137.992,137.083 C135.479,139.574 132.639,141.734 129.54,143.493 L138.09,158.264 C142.452,155.725 146.448,152.628 149.978,149.069 L137.992,137.083"
                        id="Fill-46"
                        fill="#A5D7E1"
                      ></path>
                      <path
                        d="M129.54,143.493 C126.418,145.267 123.034,146.631 119.461,147.517 L123.84,164.148 C128.896,162.826 133.68,160.831 138.09,158.264 L129.54,143.493"
                        id="Fill-48"
                        fill="#9DCA78"
                      ></path>
                      <path
                        d="M109.382,183.12 L109.009,183.119 L108.959,200.69 C109.145,200.691 109.611,200.696 109.798,200.696 C117.707,200.696 125.383,199.699 132.709,197.827 L128.203,180.715 C122.191,182.283 115.885,183.12 109.382,183.12 Z"
                        id="Fill-49"
                        fill="#979492"
                      ></path>
                      <path
                        d="M119.461,147.517 C116.366,148.284 113.129,148.693 109.798,148.693 C109.586,148.693 109.321,148.688 109.109,148.685 L109.06,166.005 L109.313,166.008 C114.332,166.008 119.2,165.36 123.84,164.148 L119.461,147.517"
                        id="Fill-50"
                        fill="#A7CF83"
                      ></path>
                      <path
                        d="M173.916,145.717 C170.662,151.389 166.681,156.589 162.102,161.193 L174.803,173.895 C180.519,168.215 185.496,161.792 189.575,154.782 L173.916,145.717"
                        id="Fill-51"
                        fill="#AED38C"
                      ></path>
                      <path
                        d="M144.48,128.676 C142.693,131.763 140.507,134.588 137.992,137.083 L149.978,149.069 C153.507,145.516 156.571,141.502 159.076,137.126 L144.48,128.676"
                        id="Fill-53"
                        fill="#979492"
                      ></path>
                      <path
                        d="M181.347,127.516 C179.672,133.965 177.154,140.073 173.916,145.717 L189.575,154.782 C193.643,147.793 196.816,140.221 198.944,132.221 L181.347,127.516"
                        id="Fill-54"
                        fill="rgba(100,100,100"
                      ></path>
                      <path
                        d="M148.555,118.747 C147.641,122.269 146.258,125.601 144.48,128.676 L159.076,137.126 C161.567,132.773 163.503,128.062 164.788,123.087 L148.555,118.747"
                        id="Fill-55"
                        fill="#8A8987"
                      ></path>
                      <path
                        d="M217.826,108.222 L202.039,108.245 C202.039,116.46 200.96,124.641 198.944,132.221 L214.29,136.324 C216.598,127.507 217.832,118.254 217.832,108.712 L217.826,108.222"
                        id="Fill-56"
                        fill="#A7A4A2"
                      ></path>
                      <path
                        d="M150.819,108.317 L149.835,108.318 C149.836,108.43 149.839,108.54 149.839,108.651 C149.839,112.139 149.393,115.522 148.555,118.747 L164.788,123.087 C165.975,118.494 166.607,113.678 166.607,108.713 C166.607,108.597 166.603,108.48 166.602,108.362 L150.819,108.317"
                        id="Fill-58"
                        fill="#98C873"
                      ></path>
                      <path
                        d="M174.582,42.795 L161.562,55.815 C166.158,60.344 170.166,65.466 173.464,71.058 L189.402,61.831 C185.303,54.846 180.311,48.449 174.582,42.795"
                        id="Fill-59"
                        fill="#A6CE82"
                      ></path>
                      <path
                        d="M149.498,67.88 L137.569,79.809 C140.099,82.245 142.31,85.012 144.132,88.039 L158.675,79.619 C156.132,75.312 153.041,71.367 149.498,67.88"
                        id="Fill-60"
                        fill="#A3A09D"
                      ></path>
                      <path
                        d="M189.402,61.831 L189.402,61.831 L173.464,71.058 C176.806,76.724 179.417,82.872 181.169,89.373 L198.946,84.691 C196.789,76.577 193.554,68.903 189.402,61.831"
                        id="Fill-61"
                        fill="#8A8987"
                      ></path>
                      <path
                        d="M214.176,80.681 L198.946,84.691 C200.944,92.209 202.021,100.102 202.039,108.245 L217.826,108.222 C217.784,98.702 216.516,89.473 214.176,80.681"
                        id="Fill-63"
                        fill="#A2D5E0"
                      ></path>
                      <path
                        d="M198.946,84.691 L198.946,84.691 L181.169,89.373 C182.795,95.403 183.683,101.736 183.725,108.271 L202.039,108.245 C202.021,100.102 200.944,92.209 198.946,84.691"
                        id="Fill-64"
                        fill="#777775"
                      ></path>
                      <path
                        d="M163.36,14.478 L155.388,28.248 C162.416,32.252 168.865,37.153 174.582,42.795 L185.762,31.616 C179.09,24.983 171.563,19.209 163.36,14.478"
                        id="Fill-65"
                        fill="#AAA7A5"
                      ></path>
                      <path
                        d="M137.642,58.903 L129.141,73.587 C132.222,75.289 135.053,77.385 137.569,79.809 L149.498,67.88 C145.963,64.4 141.98,61.377 137.642,58.903"
                        id="Fill-67"
                        fill="#7D7C7B"
                      ></path>
                      <path
                        d="M137.013,3.617 L132.869,19.118 C140.844,21.171 148.4,24.268 155.388,28.248 L163.36,14.478 C155.189,9.765 146.347,6.088 137.013,3.617"
                        id="Fill-68"
                        fill="#6E6F6E"
                      ></path>
                      <path
                        d="M128.138,36.81 L123.741,53.252 C128.663,54.529 133.327,56.444 137.642,58.903 L146.18,44.155 C140.581,40.959 134.527,38.47 128.138,36.81"
                        id="Fill-69"
                        fill="#B2D490"
                      ></path>
                      <path
                        d="M123.741,53.252 L119.329,69.754 C122.799,70.601 126.092,71.9 129.141,73.587 L137.642,58.903 C133.327,56.444 128.663,54.529 123.741,53.252"
                        id="Fill-70"
                        fill="#797977"
                      ></path>
                      <path
                        d="M109.382,34.422 L109.212,34.424 L109.163,51.42 L109.313,51.418 C114.296,51.418 119.132,52.056 123.741,53.252 L128.138,36.81 C122.146,35.253 115.861,34.422 109.382,34.422"
                        id="Fill-72"
                        fill="#B1D48E"
                      ></path>
                      <path
                        d="M109.313,51.418 L109.163,51.42 L109.114,68.618 C109.342,68.614 109.569,68.609 109.798,68.609 C113.082,68.609 116.273,69.008 119.329,69.754 L123.741,53.252 C119.132,52.056 114.296,51.418 109.313,51.418"
                        id="Fill-73"
                        fill="#9DCA78"
                      ></path>
                      <path
                        d="M3.701,80.606 C1.315,89.553 0.027,98.949 0,108.642 L17.307,108.691 C17.307,100.524 18.362,92.603 20.339,85.054 L3.701,80.606"
                        id="Fill-74"
                        fill="#8D8C8A"
                      ></path>
                      <path
                        d="M37.479,89.638 C35.854,95.735 34.975,102.138 34.952,108.742 L52.093,108.791 C52.107,103.7 52.782,98.765 54.031,94.064 L37.479,89.638"
                        id="Fill-75"
                        fill="#8D8C8A"
                      ></path>
                      <path
                        d="M34.95,108.882 L17.309,108.907 C17.327,117.003 18.38,124.856 20.34,132.341 L37.337,127.865 C35.778,121.842 34.948,115.525 34.948,109.016 L34.95,108.882"
                        id="Fill-76"
                        fill="#9DCA78"
                      ></path>
                      <path
                        d="M67.622,108.836 L52.093,108.858 L52.091,108.957 C52.091,113.978 52.732,118.849 53.935,123.495 L71.252,118.935 C70.432,115.724 69.995,112.36 69.995,108.895 L69.996,108.843 L67.622,108.836"
                        id="Fill-78"
                        fill="#B1D48E"
                      ></path>
                      <path
                        d="M109.011,34.427 C102.514,34.459 96.215,35.324 90.213,36.922 L94.538,53.346 C99.166,52.113 104.178,51.446 109.189,51.422 L109.209,34.427"
                        id="Fill-24"
                        fill="#ccc"
                      ></path>

                      <path
                        onMouseLeave={() => {
                          this.setState({ hoverItem: null })
                        }}
                        onMouseEnter={() => {
                          this.setState({ hoverItem: "external" })
                        }}
                        onClick={() => this.props.setActiveItem("external")}
                        className={classNames(
                          "panel external",
                          hoverItem === "external" && "active"
                        )}
                        d="M146.679,173.099 L146.679,173.099 C140.96,176.422 134.758,179.005 128.203,180.715 L132.709,197.827 C140.829,195.752 148.517,192.6 155.613,188.531 L146.679,173.099"
                        id="Fill-47"
                        fill="#AED38C"
                      ></path>

                      <path
                        onMouseLeave={() => {
                          this.setState({ hoverItem: null })
                        }}
                        onMouseEnter={() => {
                          this.setState({ hoverItem: "sync" })
                        }}
                        onClick={() => this.props.setActiveItem("sync")}
                        className={classNames(
                          "panel sync",
                          hoverItem === "sync" && "active"
                        )}
                        d="M159.076,137.126 C156.571,141.502 153.507,145.516 149.978,149.069 L162.102,161.193 C166.681,156.589 170.662,151.389 173.916,145.717 L159.076,137.126"
                        id="Fill-52"
                        fill="#666867"
                      ></path>

                      <path
                        onMouseLeave={() => {
                          this.setState({ hoverItem: null })
                        }}
                        onMouseEnter={() => {
                          this.setState({ hoverItem: "feedback" })
                        }}
                        onClick={() => this.props.setActiveItem("feedback")}
                        className={classNames(
                          "panel feedback",
                          hoverItem === "feedback" && "active"
                        )}
                        d="M109.798,16.209 C109.619,16.209 109.442,16.214 109.264,16.215 L109.212,34.424 L109.382,34.422 C115.861,34.422 122.146,35.253 128.138,36.81 L132.869,19.118 C125.495,17.219 117.764,16.209 109.798,16.209"
                        id="Fill-71"
                        fill="#8B8A88"
                      ></path>

                      <path
                        onMouseLeave={() => {
                          this.setState({ hoverItem: null })
                        }}
                        onMouseEnter={() => {
                          this.setState({ hoverItem: "badges" })
                        }}
                        onClick={() => this.props.setActiveItem("badges")}
                        className={classNames(
                          "panel badges",
                          hoverItem === "badges" && "active"
                        )}
                        d="M146.18,44.155 L137.642,58.903 C141.98,61.377 145.963,64.4 149.498,67.88 L161.562,55.815 C156.977,51.297 151.81,47.368 146.18,44.155"
                        id="Fill-66"
                        fill="#70C4D5"
                      ></path>

                      <path
                        onMouseLeave={() => {
                          this.setState({ hoverItem: null })
                        }}
                        onMouseEnter={() => {
                          this.setState({ hoverItem: "reporting" })
                        }}
                        onClick={() => this.props.setActiveItem("reporting")}
                        className={classNames(
                          "panel reporting",
                          hoverItem === "reporting" && "active"
                        )}
                        d="M56.613,161.144 L56.613,161.144 L44.328,173.429 C49.937,179.08 56.273,184.007 63.184,188.062 L71.907,172.994 C66.3,169.715 61.16,165.725 56.613,161.144"
                        id="Fill-35"
                        fill="#6E6F6E"
                      ></path>

                      <path
                        onMouseLeave={() => {
                          this.setState({ hoverItem: null })
                        }}
                        onMouseEnter={() => {
                          this.setState({ hoverItem: "mobile" })
                        }}
                        onClick={() => this.props.setActiveItem("mobile")}
                        className={classNames(
                          "panel mobile",
                          hoverItem === "mobile" && "active"
                        )}
                        d="M94.382,164.039 L94.382,164.039 L89.965,180.554 C96.041,182.193 102.424,183.084 109.011,183.115 L109.06,166.005 C103.984,165.983 99.064,165.299 94.382,164.039"
                        id="Fill-41"
                        fill="#BAE0E6"
                      ></path>

                      <path
                        onMouseLeave={() => {
                          this.setState({ hoverItem: null })
                        }}
                        onMouseEnter={() => {
                          this.setState({ hoverItem: "hq" })
                        }}
                        onClick={() => this.props.setActiveItem("hq")}
                        className={classNames(
                          "panel hq",
                          hoverItem === "hq" && "active"
                        )}
                        d="M173.464,71.058 L158.675,79.619 C161.254,83.984 163.269,88.721 164.623,93.73 L181.169,89.373 C179.417,82.872 176.806,76.724 173.464,71.058"
                        id="Fill-62"
                        fill="#A5A29F"
                      ></path>

                      <path
                        onMouseLeave={() => {
                          this.setState({ hoverItem: null })
                        }}
                        onMouseEnter={() => {
                          this.setState({ hoverItem: "community" })
                        }}
                        onClick={() => this.props.setActiveItem("community")}
                        className={classNames(
                          "panel community",
                          hoverItem === "community" && "active"
                        )}
                        d="M202.039,108.245 L183.725,108.271 C183.726,108.391 183.731,108.65 183.731,108.771 C183.731,115.246 182.902,121.527 181.347,127.516 L198.944,132.221 C200.96,124.641 202.039,116.46 202.039,108.245"
                        id="Fill-57"
                        fill="#696A69"
                      ></path>

                      <path
                        onMouseLeave={() => {
                          this.setState({ hoverItem: null })
                        }}
                        onMouseEnter={() => {
                          this.setState({ hoverItem: "guided" })
                        }}
                        onClick={() => this.props.setActiveItem("guided")}
                        className={classNames(
                          "panel guided",
                          hoverItem === "guided" && "active"
                        )}
                        d="M90.213,36.922 L90.213,36.922 C83.737,38.645 77.608,41.219 71.956,44.52 L80.458,59.208 C84.818,56.661 89.544,54.675 94.538,53.346 L90.213,36.922"
                        id="Fill-21"
                        fill="#99D2DE"
                      ></path>

                      <path
                        onMouseLeave={() => {
                          this.setState({ hoverItem: null })
                        }}
                        onMouseEnter={() => {
                          this.setState({ hoverItem: "dashboard" })
                        }}
                        onClick={() => this.props.setActiveItem("dashboard")}
                        className={classNames(
                          "panel dashboard",
                          hoverItem === "dashboard" && "active"
                        )}
                        d="M29.673,62.72 C25.712,69.645 22.625,77.131 20.558,85.03 L37.54,89.572 C39.239,83.2 41.761,77.164 44.988,71.586 L29.673,62.72"
                        id="Fill-29"
                        fill="#ACD189"
                      ></path>

                      <path
                        onMouseLeave={() => {
                          this.setState({ hoverItem: null })
                        }}
                        onMouseEnter={() => {
                          this.setState({ hoverItem: "google" })
                        }}
                        onClick={() => this.props.setActiveItem("google")}
                        className={classNames(
                          "panel google",
                          hoverItem === "google" && "active"
                        )}
                        d="M52.093,108.858 L34.95,108.882 L34.948,109.016 C34.948,115.525 35.778,121.842 37.337,127.865 L53.935,123.495 C52.732,118.849 52.091,113.978 52.091,108.957 L52.093,108.858"
                        id="Fill-77"
                        fill="#C3E3E8"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default HubContainer
