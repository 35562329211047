import { useState } from "react"
import classNames from "classnames"

import HubContainer from "./components/HubContainer"
import MarketingHubContainer from "./components/MarketingHubContainer"

import Slide from "./components/Slide"
import logo from "./img/logo.png"
import "./styles/index.scss"

function App() {
  const [zoomLevel, setZoomLevel] = useState(100)
  const [activeItem, setActiveItem] = useState(null)

  function setZoom(type) {
    let newZoom = type === "increase" ? zoomLevel + 10 : zoomLevel - 10
    if (
      (type === "increase" && zoomLevel < 150) ||
      (type === "decrease" && zoomLevel > 40)
    ) {
      setZoomLevel(newZoom)
    }
  }

  const isMarketing = window.location.href.includes("marketing")

  return (
    <>
      <div
        className={classNames(
          "appWrapper",
          activeItem,
          isMarketing && "marketing"
        )}
        style={{ zoom: !isMarketing ? zoomLevel + "%" : "50%" }}
      >
        {!isMarketing ? (
          <HubContainer
            activeItem={activeItem}
            setActiveItem={(item) => {
              setActiveItem(item)
            }}
          />
        ) : (
          <MarketingHubContainer />
        )}
      </div>

      {!isMarketing && (
        <img
          className={classNames("logo", activeItem && "hide")}
          src={logo}
          alt="MobileMind"
        />
      )}

      {activeItem && (
        <div
          onClick={() => {
            setActiveItem(null)
          }}
          className="back"
        />
      )}

      <Slide
        goBack={() => {
          setActiveItem(null)
        }}
        activeItem={activeItem}
      />

      {!activeItem && !isMarketing && (
        <div className="setZoom">
          <div onClick={() => setZoom("decrease")} />
          <div onClick={() => setZoom("increase")} />
        </div>
      )}
    </>
  )
}

export default App
