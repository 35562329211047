import { createRef, Component } from "react"
import classNames from "classnames"
import { slideData } from "../index"

import ReactPlayer from "react-player"

class VideoPlayer extends Component {
  constructor(props) {
    super(props)
    this.player = createRef()
    this.state = { isPlaying: props.autoPlay }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.seek !== this.props.seek) {
      this.player.current.seekTo(this.props.seek, "seconds")
    }
  }

  goBack = () => {
    setTimeout(() => {
      this.player.current.seekTo(0, "seconds")
      this.props.goBack()
    }, 750)
  }

  render() {
    const { isPlaying } = this.state

    return (
      <>
        {!isPlaying && (
          <>
            <div
              className="playButton"
              onClick={() => {
                this.setState({ isPlaying: true })
              }}
            ></div>
            <div className="description">
              <span className={classNames("icon", this.props.activeItem)} />
              <p>{this.props.description}</p>
            </div>
          </>
        )}

        <ReactPlayer
          ref={this.player}
          url={this.props.url}
          config={{
            file: {
              attributes: {
                muted: true,
                preload: "auto",
              },
            },
          }}
          controls={true}
          playing={isPlaying}
          className="videoPlayer"
          width={"65vw"}
          height={"100%"}
          onPause={() => {
            this.setState({ isPlaying: false })
          }}
          onPlay={() => {
            this.setState({ isPlaying: true })
          }}
        />
      </>
    )
  }
}

class Slide extends Component {
  render() {
    const url =
      window.location !== window.parent.location
        ? document.referrer
        : document.location.href

    if (
      url === "https://hub.mobilemind.io/" ||
      url === "https://mobilemind-hub-dev.web.app/"
    ) {
      return (
        <div
          style={{ pointerEvents: this.props.activeItem ? "all" : "none" }}
          className={classNames("slide", this.props.activeItem)}
        >
          <h1>
            {this.props.activeItem && slideData[this.props.activeItem].title}
          </h1>

          <div
            className="slideInner"
            style={{ pointerEvents: this.props.activeItem ? "all" : "none" }}
          >
            {Object.entries(slideData).map((slide) => {
              return (
                <div
                  key={slide[0]}
                  className="videoWrapper"
                  style={{
                    position: "absolute",
                    pointerEvents:
                      this.props.activeItem === slide[0] ? "all" : "none",
                  }}
                >
                  <div
                    className="video"
                    style={{
                      transition: ".5s",
                      opacity:
                        this.props.activeItem &&
                        this.props.activeItem === slide[0]
                          ? 1
                          : 0,
                    }}
                  >
                    <VideoPlayer
                      autoPlay={true}
                      activeItem={this.props.activeItem}
                      url={slide[1].video}
                      goBack={this.props.goBack}
                      description={slide[1].description}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )
    }
    return (
      <>
        {this.props.activeItem && (
          <div className={classNames("slide", this.props.activeItem)}>
            <h1>{slideData[this.props.activeItem].title}</h1>
            {slideData[this.props.activeItem].video && (
              <div className="videoWrapper">
                <div className="video">
                  <VideoPlayer
                    autoPlay={false}
                    activeItem={this.props.activeItem}
                    url={slideData[this.props.activeItem].video}
                    goBack={this.props.goBack}
                    description={slideData[this.props.activeItem].description}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </>
    )
  }
}

export default Slide
